import { styled } from '@mui/system'
import { type PageProps } from 'gatsby'
import * as React from 'react'
import { EngageRings } from '../../../components/EngageRings'
import { Layout } from '../../../components/Layout'
import { SEO } from '../../../components/SEO'
import { Txt } from '../../../components/Txt'
import { useEngageRingParams } from '../../../hooks/useEngageRingParams'

const DESCRIPTION =
    '格安の婚約指輪（0.3カラット）は卸売価格だから実現できる高品質で低価格なエンゲージリングならミスプラチナでご購入いただけます。'
export const Head = () => (
    <SEO
        title="安い婚約指輪・激安エンゲージリング（0.3カラット）をお探しなら｜ミスプラチナ"
        description={DESCRIPTION}
        page="engagering/carat/0.3カラット"
    />
)

const C3Page: React.FC<PageProps> = ({ location }) => {
    const title = (
        <H1>
            <Txt size="l">0.3カラット</Txt>
            <Txt size="s">の婚約指輪を探す</Txt>
        </H1>
    )
    const params = useEngageRingParams(location.search)
    return (
        <Layout
            breadcrumbs={[
                { name: '安い婚約指輪・エンゲージリング', path: 'engagering/' },
                {
                    name: 'カラットからダイヤモンドの婚約指輪を探す',
                    path: 'engagering/carat/',
                },
                { name: '0.3カラット' },
            ]}
        >
            <EngageRings
                title={title}
                description={DESCRIPTION}
                {...params}
                c3
                carat
            />
        </Layout>
    )
}

const H1 = styled('h1')({
    margin: '0 0 8px',
    lineHeight: '1',
})

export default C3Page
